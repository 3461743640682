"use strict";
Object.defineProperty(exports, "__esModule", { value: true });

var system = require('durandal/system');
var viewEngine = require('durandal/viewEngine');
var composition = require('durandal/composition');
var Events = require('durandal/events');
var $ = require('jquery');

var dialog = require('./plugins/dialog');
var router = require('./plugins/router');
var widget = require('./plugins/widget');
var http = require('./plugins/http');
var observable = require('./plugins/observable');
var serializer = require('./plugins/serializer');

var app = {
    title: 'Application',

    start: function () {
        system.log('Application:Starting');

        if (this.title) {
            document.title = this.title;
        }

        var loaded = [dialog, router, widget, http, observable, serializer];

        for (var i = 0; i < loaded.length; i++) {
            var currentModule = loaded[i];

            if (currentModule.install) {
                currentModule.install({});                
            } 
        }

        system.log('Application:Started');

        return Promise.resolve();
    },
    /**
     * Sets the root module/view for the application.
     * @method setRoot
     * @param {string} root The root view or module.     
     * @param {string} [applicationHost] The application host element or id. By default the id 'applicationHost' will be used.
     */
    setRoot: function (root, applicationHost) {
        var hostElement, settings = { activate: true, transition: null };
        const asyncContext = new Promise((resolve, reject) => {
            if (!applicationHost || system.isString(applicationHost)) {
                hostElement = document.getElementById(applicationHost || 'applicationHost');
            } else {
                hostElement = applicationHost;
            }
    
            if (system.isString(root)) {
                if (viewEngine.isViewUrl(root)) {
                    settings.view = root;
                } else {
                    settings.model = root;
                }
            } else {
                settings.model = root;
            }
    
            function finishComposition() {
                if (settings.model) {
                    if (settings.model.canActivate) {
                        try {
                            var result = settings.model.canActivate();
                            if (result && result.then) {
                                result.then(function (actualResult) {
                                    if (actualResult) {
                                        composition.compose(hostElement, settings);
                                        resolve();
                                    }
                                }).fail(function (err) {
                                    system.error(err);
                                    reject(err);
                                });
                            } else if (result) {
                                composition.compose(hostElement, settings);
                                resolve();
                            }
                        } catch (er) {
                            system.error(er);
                            reject(err);
                        }
                    } else {
                        composition.compose(hostElement, settings);
                        resolve();
                    }
                } else {
                    composition.compose(hostElement, settings);
                    resolve();
                }
            }
    
            if (system.isString(settings.model)) {
                system.acquire(settings.model).then(function (module) {
                    settings.model = system.resolveObject(module);
                    finishComposition();
                }).fail(function (err) {
                    system.error('Failed to load root module (' + settings.model + '). Details: ' + err.message);
                });
            } else {
                finishComposition();
            }
        });
        
        return asyncContext;
    }
};

exports.title = app.title;
exports.configurePlugins = app.configurePlugins;
exports.start = app.start;
exports.setRoot = app.setRoot;

Events.includeIn(exports);
