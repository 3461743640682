/*!
*  filename: ej.localetexts.pt-PT.js
*  Copyright Syncfusion Inc. 2001 - 2016. All rights reserved.
*  Use of this code is subject to the terms of our license.
*  A copy of the current license can be obtained at any time by e-mailing
*  licensing@syncfusion.com. Any infringement will be prosecuted under
*  applicable laws. 
*/

ej.Autocomplete.Locale["pt-PT"] = {
    addNewText: "Adicionar novo",
    emptyResultText: "Sem sugestões",
    actionFailure: "O campo especificado não existe na origem de dados",
};
ej.ColorPicker.Locale["pt-PT"] = {
    buttonText: {
        apply: "Aplique",
        cancel: "Cancelar",
        swatches: "Amostras"
    },
    tooltipText: {
        switcher: "Interruptor",
        addbutton: "Adicionar cor",
        basic: "Básico",
        monochrome: "Monocromático",
        flatcolors: "Cores planas",
        seawolf: "Lobo do mar",
        webcolors: "Cores Web",
        sandy: "Sandy",
        pinkshades: "Pink Shades",
        misty: "enevoado",
        citrus: "Citrus",
        vintage: "Vintage",
        moonlight: "Luar",
        candycrush: "Candy Crush",
        currentcolor: "Cor atual",
        selectedcolor: "Cor selecionada"
    },
};
ej.CurrencyTextbox.Locale["pt-PT"] = {
    watermarkText: "Introduzir o valor",
};
ej.DatePicker.Locale["pt-PT"] = {
    watermarkText: "Seleccionar data",
    buttonText: "Hoje",
};
ej.DateRangePicker.Locale["pt-PT"] = {
    ButtonText: {
        apply: "Aplique",
        cancel: "Cancelar",
        reset: "Reiniciar"
    },
    watermarkText: "Selecionar intervalo",
    customPicker: "Selecionador personalizado",
};
ej.DateTimePicker.Locale["pt-PT"] = {
    watermarkText: "Selecione a hora da data",
    buttonText: {
        today: "Hoje",
        timeNow: "Horário",
        done: "Feito",
        timeTitle: "Tempo"
    },
};
ej.Dialog.Locale["pt-PT"] = {
    tooltip: {
        close: "Fechar",
        collapse: "Colapso",
        restore: "Restaurar",
        maximize: "Maximizar",
        minimize: "Minimizar",
        expand: "Expandir",
        unPin: "desencavilhar",
        pin: "Pino"
    },
    closeIconTooltip: "Fechar",
};
ej.DropDownList.Locale["pt-PT"] = {
	emptyResultText: "Nenhuma sugestão,",
	watermarkText: " ",
};
ej.Grid.Locale["pt-PT"] = {
    EmptyRecord: "Não há registros a serem exibidos",
    GroupDropArea: "Arraste um cabeçalho de coluna aqui para grupo a sua coluna",
    DeleteOperationAlert: "Não há registros selecionados para a operação de exclusão",
    EditOperationAlert: "Não há registros selecionados para a operação de edição",
    SaveButton: "Salvar",
    OkButton: "Está bem",
    CancelButton: "Cancelar",
    EditFormTitle: "Detalhes de",
    AddFormTitle: "Adicionar novas informações",
    Notactionkeyalert: "Este-combinação de teclas não está disponível",
    Keyconfigalerttext: "Este Key-Combination já foi atribuído a",
    GroupCaptionFormat: "{{:headerText}}: {{:key}} - {{:count}} {{if count == 1 }} item {{else}} itens {{/if}} ",
    BatchSaveConfirm: "Tem certeza de que deseja salvar as alterações?",
    BatchSaveLostChanges: "As alterações não salvas serão perdidas. Você tem certeza que quer continuar?",
    ConfirmDelete: "Tem certeza de que deseja excluir Record?",
    CancelEdit: "Tem certeza de que deseja cancelar as mudanças?",
    PagerInfo: "{0} de {1} páginas ({2} itens)",
    FrozenColumnsViewAlert: "colunas congeladas devem estar na área gridview",
    FrozenColumnsScrollAlert: "Ativar permitem rolagem ao usar colunas congeladas",
    FrozenNotSupportedException: "Colunas congeladas e linhas não são suportados para Agrupamento, Template Row, Template Detalhe, Hierarquia Grade e Batch Edição",
    Add: "Adicionar",
    Edit: "Editar",
    Delete: "Excluir",
    Update: "Atualizar",
    Cancel: "Cancelar",
    Done: "Feito",
    Columns: "colunas",
    SelectAll: "(Selecionar tudo)",
    PrintGrid: "Impressão",
    ExcelExport: "Excel Export",
    WordExport: "palavra Export",
    PdfExport: "PDF Export",
    StringMenuOptions: [{ text: "Começa com", value: "StartsWith" }, { text: "Termina com", value: "EndsWith" }, { text: "Contém", value: "Contains" }, { text: "Igual", value: "Equal" }, { text: "Diferente", value: "NotEqual" }, ],
    NumberMenuOptions: [{ text: "Menos que", value: "LessThan" }, { text: "Melhor que", value: "GreaterThan" }, { text: "Menor ou equal", value: "LessThanOrEqual" }, { text: "Maior ou equal", value: "GreaterThanOrEqual" }, { text: "Igual", value: "Equal" }, { text: "Diferente", value: "NotEqual" }, ],
    PredicateAnd: "E",
    PredicateOr: "OU",
    Filter: "Filtrar",
    FilterMenuCaption: "Valor de filtro",
    FilterbarTitle: "celular barra de filtragem s",
    MatchCase: "Caso de compatibilidade",
    Clear: "Limpar",
    ResponsiveFilter: "Filtro",
    ResponsiveSorting: "Ordenar",
    Search: "Pesquisar",
    DatePickerWaterMark: "Seleccionar data",
    EmptyDataSource: "DataSource não pode estar vazio na carga inicial desde colunas são geradas a partir dataSource em Geração automática coluna de grelha",
    ForeignKeyAlert: "O valor atualizado deve ser um valor de chave estrangeira válida",
    True: "verdade",
    False: "falso",
    UnGroup: "Clique aqui para desagrupar",
    AddRecord: "Adicionar registro",
    EditRecord: "Editar Gravar",
    DeleteRecord: "excluir registro",
    Save: "Salvar",
    Grouping: "Grupo",
    Ungrouping: "Desagrupar",
    SortInAscendingOrder: "Classificar em Ordem Crescente",
    SortInDescendingOrder: "Organizar por ordem descendente",
    NextPage: "Próxima página",
    PreviousPage: "Página anterior",
    FirstPage: "Primeira página",
    LastPage: "Última página",
    EmptyRowValidationMessage: "Pelo menos um campo deve ser atualizado",
    NoResult: "Nenhuma equivalência encontrada"
};
;
if (ej.mobile !== undefined && ej.mobile.Grid !== undefined) {
    ej.mobile.Grid.Locale["pt-PT"] = {
        emptyResult: "Não há registros a serem exibidos",
        filterValidation: "Insira os dados de filtro válidos",
        filterTypeValidation: "Insira os dados de filtro válidos. A coluna filtro atual é do tipo",
        captionText: "itens",
        spinnerText: "Carregando...",
        HideColumnAlert: "Pelo menos uma coluna deve ser exibida na grade",
        columnSelectorText: "Ocultar Coluna",
        columnSelectorDone: "Está bem",
        columnSelectorCancel: "Cancelar",
        columnSelectorWarning: "Aviso",
        filterOk: "Está bem",
        filterWarning: "Aviso"
    };
    ;
}
if (ej.mobile !== undefined && ej.mobile.DatePicker !== undefined) {
    ej.mobile.DatePicker.Locale["pt-PT"] = {
        confirmText: "Feito",
        Windows: {
            cancelText: "Cancelar",
            headerText: "Escolher data",
            toolbarConfirmText: "feito",
            toolbarCancelText: "Fechar"
        },
    };
    ;
}
if (ej.mobile !== undefined && ej.mobile.TimePicker !== undefined) {
    ej.mobile.TimePicker.Locale["pt-PT"] = {
        confirmText: "Feito",
        AM: "SOU",
        PM: "PM",
        Android: {
            headerText: "Definir tempo"
        },
        Windows: {
            cancelText: "Cancelar",
            headerText: "ESCOLHA TEMPO",
            toolbarCancelText: "Fechar",
            toolbarConfirmText: "feito"
        },
    };
    ;
}
ej.NumericTextbox.Locale["pt-PT"] = {
    watermarkText: "Introduzir o valor",
};
ej.Pager.Locale["pt-PT"] = {
    pagerInfo: "{0} de {1} páginas ({2} itens)",
    firstPageTooltip: "Ir para a primeira página",
    lastPageTooltip: "Ir para a última página",
    nextPageTooltip: "Ir para a próxima página",
    previousPageTooltip: "Ir para a página anterior",
    nextPagerTooltip: "Ir para a próxima página",
    previousPagerTooltip: "Ir para página anterior",
};
ej.PercentageTextbox.Locale["pt-PT"] = {
    watermarkText: "Introduzir o valor",
};
ej.RTE.Locale["pt-PT"] = {
	bold: "Negrito",
	italic: "itálico",
	underline: "Sublinhado",
	strikethrough: "Riscado",
	superscript: "Sobrescrito",
	subscript: "Subscrito",
	justifyCenter: "Alinhar o centro de texto",
	justifyLeft: "Alinhar texto à esquerda",
	justifyRight: "Alinhar o texto à direita",
	justifyFull: "Justificar",
	unorderedList: "Inserir lista não ordenada",
	orderedList: "Inserir lista ordenada",
	indent: "Aumentar recuo",
	fileBrowser: "Navegador de arquivos",
	outdent: "Recuar devagar",
	cut: "Cortar",
	copy: "Cópia",
	paste: "Colar",
	paragraph: "Parágrafo",
	undo: "Desfazer",
	redo: "Refazer",
	upperCase: "maiúscula",
	lowerCase: "minúscula",
	clearAll: "Limpar tudo",
	clearFormat: "Limpar formato",
	createLink: "Inserir / Editar hiperligação",
	removeLink: "Remover hiperlink",
	tableProperties: "Propriedades da tabela",
	insertTable: "Inserir",
	deleteTables: "Excluir",
	imageProperties: "Propriedades da imagem",
	openLink: "Abrir hiperlink",
	image: "Inserir imagem",
	video: "Inserir vídeo",
	editTable: "Editar Propriedades da Tabela",
	embedVideo: "Cole seu código embed abaixo",
	viewHtml: "Ver HTML",
	fontName: "Selecione a família de fontes",
	fontSize: "Selecione o tamanho da fonte",
	fontColor: "Selecionar cor",
	format: "Formato",
	backgroundColor: "Cor de fundo",
	style: "Estilos",
	deleteAlert: "Tem certeza de que deseja limpar todo o conteúdo?",
	copyAlert: "Seu navegador não suporta acesso direto à área de transferência. Use o atalho de teclado Ctrl + C em vez da operação de cópia.",
	pasteAlert: "Seu navegador não suporta acesso direto à área de transferência. Use o atalho de teclado Ctrl + V em vez da operação de colar.",
	cutAlert: "Seu navegador não suporta acesso direto à área de transferência. Use o atalho de teclado Ctrl + X em vez da operação de corte.",
	videoError: "A área de texto não pode estar vazia",
	imageWebUrl: "Endereço Web",
	imageAltText: "Texto alternativo",
	dimensions: "Dimensões",
	constrainProportions: "Restringir Proporções",
	linkWebUrl: "Endereço Web",
	imageLink: "Imagem como link",
	imageBorder: "Borda da imagem",
	imageStyle: "Estilo",
	linkText: "Texto",
	linkToolTip: "Dica de ferramenta",
	html5Support: "Este ícone de ferramenta só está habilitado nos navegadores compatíveis com HTML5",
	linkOpenInNewWindow: "Abrir link em nova janela",
	tableColumns: "Número de colunas",
	tableRows: "Nº de linhas",
	tableWidth: "Largura",
	tableHeight: "Altura",
	tableCellSpacing: "Cellspacing",
	tableCellPadding: "Cellpadding",
	tableBorder: "Fronteira",
	tableCaption: "Rubrica",
	tableAlignment: "Alinhamento",
	textAlign: "Alinhamento de texto",
	dialogUpdate: "Atualizar",
	dialogInsert: "Inserir",
	dialogCancel: "Cancelar",
	dialogApply: "Aplique",
	dialogOk: "Está bem",
	createTable: "Inserir tabela",
	insertTable: "Inserir",
	addColumnLeft: "Inserir colunas à esquerda",
	addColumnRight: "Inserir colunas à direita",
	addRowAbove: "Inserir linhas acima",
	addRowBelow: "Inserir linhas abaixo",
	deleteRow: "Eliminar linha inteira",
	deleteColumn: "Excluir coluna inteira",
	deleteTable: "Excluir tabela",
	customTable: "Criar tabela personalizada ...",
	characters: "Personagens",
	words: "Palavras",
	general: "Geral",
	advanced: "Avançado",
	table: "Mesa",
	row: "Linha",
	column: "Coluna",
	cell: "Célula",
	solid: "Sólido",
	dotted: "Pontilhado",
	dashed: "Tracejadas",
	doubled: "Duplo",
	maximize: "Maximizar",
	resize: "Minimizar",
	swatches: "Amostras",
	paragraph: "Parágrafo",
	quotation: "Cotação",
	heading1: "Rubrica 1",
	heading2: "Rubrica 2",
	heading3: "Rubrica 3",
	heading4: "Rubrica 4",
	heading5: "Rubrica 5",
	heading6: "Rubrica 6",
	segoeui: "Segoe UI",
	arial: "Arial",
	couriernew: "Courier Novo",
	georgia: "Geórgia",
	impact: "Impacto",
	lucidaconsole: "Lucida Console",
	tahoma: "Tahoma",
	timesnewroman: "Times New Roman",
	trebuchetms: "Trebuchet MS",
	verdana: "Verdana",
	disc: "Disco",
	circle: "Círculo",
	square: "Quadrado",
	number: "Número",
	loweralpha: "Alfa Baixo",
	upperalpha: "Alfa Superior",
	lowerroman: "Baixa romana",
	upperroman: "Alto Romano",
	none: "Nenhum",
	linktooltip: "Ctrl + clique para seguir o link",
	charSpace: "Caracteres (com espaços)",
	charNoSpace: "Caracteres (sem espaços)",
	wordCount: "Contagem de palavras",
	left: "Esquerda",
	right: "Certo",
	center: "Centro",
	zoomIn: "mais Zoom",
	zoomOut: "Reduzir",
	print: "Impressão",
	wordExport: "Exportar como documento do Word",
	pdfExport: "Exportar como arquivo PDF",
	FindAndReplace: "Localizar e substituir",
	Find: "Encontrar",
	MatchCase: "Caso de compatibilidade",
	WholeWord: "Palavra inteira",
	ReplaceWith: "Substituir com",
	Replace: "Substituir",
	ReplaceAll: "Substitua tudo",
	FindErrorMsg: "Não foi possível encontrar a palavra especificada.",
};
ej.Schedule.Locale["pt-PT"] = {
    ReminderWindowTitle: "janela lembrete",
    CreateAppointmentTitle: "Criar compromisso",
    RecurrenceEditTitle: "Editar Repeat Appointment",
    RecurrenceEditMessage: "Como você gostaria de mudar a designação da série?",
    RecurrenceEditOnly: "Só esta nomeação",
    RecurrenceEditSeries: "série inteira",
    PreviousAppointment: "Nomeação Anterior",
    NextAppointment: "Próximo compromisso",
    AppointmentSubject: "Sujeito",
    StartTime: "start Time",
    EndTime: "Fim do tempo",
    AllDay: "Dia todo",
    StartTimeZone: "Iniciar fuso horário",
    EndTimeZone: "Fuso horário final",
    Today: "Hoje",
    Recurrence: "Repetir",
    Done: "Feito",
    Cancel: "Cancelar",
    Ok: "Está bem",
    RepeatBy: "Repita por",
    RepeatEvery: "repetir todos",
    RepeatOn: "Repita no",
    StartsOn: "começa em",
    Ends: "extremidades",
    Summary: "Resumo",
    Daily: "Diariamente",
    Weekly: "Semanal",
    Monthly: "Por mês",
    Yearly: "Anual",
    Every: "Cada",
    EveryWeekDay: "Todos os dias da semana",
    Never: "Nunca",
    After: "Depois de",
    Occurence: "Ocorrência (s)",
    On: "Em",
    Edit: "Editar",
    RecurrenceDay: "Dias)",
    RecurrenceWeek: "Semana (s)",
    RecurrenceMonth: "Mês (s)",
    RecurrenceYear: "Anos)",
    The: "o",
    OfEvery: "de cada",
    First: "Primeiro",
    Second: "Segundo",
    Third: "Terceiro",
    Fourth: "Quarto",
    Last: "Último",
    WeekDay: "dia da semana",
    WeekEndDay: "Day Weekend",
    Subject: "Sujeito",
    Categorize: "Categorias",
    DueIn: "Due In",
    DismissAll: "Recusar tudo",
    Dismiss: "demitir",
    OpenItem: "Item aberto",
    Snooze: "Soneca",
    Day: "Dia",
    Week: "Semana",
    WorkWeek: "Semana de trabalho",
    Month: "Mês",
    AddEvent: "Adicionar Evento",
    CustomView: "Exibição personalizada",
    Agenda: "Agenda",
    Detailed: "Editar compromisso",
    EventBeginsin: "Nomeação começa na",
    Editevent: "Editar compromisso",
    Editseries: "Editar Series",
    Times: "vezes",
    Until: "até",
    Eventwas: "nomeação era",
    Hours: "hrs",
    Minutes: "mins",
    Overdue: "Nomeação atrasada",
    Days: "dias)",
    Event: "Evento",
    Select: "selecionar",
    Previous: "prev",
    Next: "Próximo",
    Close: "Fechar",
    Delete: "Excluir",
    Date: "Encontro",
    Showin: "Mostrar em",
    Gotodate: "Ir para a data",
    Resources: "RECURSOS",
    RecurrenceDeleteTitle: "Excluir Repeat Appointment",
    Location: "Localização",
    Priority: "Prioridade",
    RecurrenceAlert: "Alerta",
    NoTitle: "No Title",
    OverFlowAppCount: "mais compromissos)",
    WrongPattern: "O padrão de recorrência não é válido",
    CreateError: "A duração do compromisso deve ser menor do que a frequência com que ocorre. Encurtar a duração ou altere o padrão de recorrência na caixa de diálogo Nomeação de recorrência.",
    DragResizeError: "Não é possível reagendar uma ocorrência do compromisso recorrente se ele salta sobre uma ocorrência posterior do mesmo compromisso.",
    StartEndError: "tempo final deve ser maior do que a hora de início",
    MouseOverDeleteTitle: "excluir compromisso",
    DeleteConfirmation: "Tem certeza de que deseja excluir este compromisso?",
    Time: "Tempo",
    EmptyResultText: "Sem sugestões",
};
ej.Uploadbox.Locale["pt-PT"] = {
    buttonText: {
        upload: "Envio",
        browse: "procurar",
        cancel: "Cancelar",
        close: "Fechar"
    },
    dialogText: {
        title: "Carregar Box",
        name: "Nome",
        size: "Tamanho",
        status: "estado"
    },
    dropAreaText: "Soltar arquivos ou clique para fazer upload",
    filedetail: "O tamanho do arquivo selecionado é muito grande. Selecione um arquivo dentro do tamanho válido.",
    denyError: "Arquivos com extensões #Extension não são permitidos.",
    allowError: "Somente os arquivos com extensões #Extension são permitidos.",
    cancelToolTip: "Cancelar",
    removeToolTip: "Remover",
    retryToolTip: "tentar novamente",
    completedToolTip: "concluído",
    failedToolTip: "fracassado",
    closeToolTip: "Fechar",
};
;
